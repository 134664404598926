import API from '@aws-amplify/api';
import { atom } from 'recoil';

import { getCorporationDetailByCorporationDomain } from '@/service/organizationService';
import localStorageUtil from '@/common/utils/localStorageUtil';
import Constant from '@/common/constant/Constant';
import Utils from '@/common/utils/utils';



/**
 * Create store save corporation detail state
 */
export const corporationAtom = atom({
    key: 'corporationAtom',
    default: {
        corporationName: '',
        corporationLogo: Constant.LOGO_DEFAULT,
        isLoadDataWithAPI: false
    },
    effects: [
        async ({ setSelf, onSet, trigger, getPromise, getLoadable }) => {
            try {
                let corporationDomain = await localStorageUtil.getCorporationDomain();
                let corporationDetail = await getCorporationDetailByCorporationDomain(API, corporationDomain);
                if (Utils.isEmptyObject(corporationDetail)) {
                    setSelf({
                        corporationName: '',
                        corporationLogo: Constant.LOGO_DEFAULT,
                        isLoadDataWithAPI: true
                    });
                }
                setSelf(corporationDetail);
            } catch (err) {
                console.error(`atom get corporation detail error: ${err}`);
                // return default
                setSelf({
                    corporationName: '',
                    corporationLogo: Constant.LOGO_DEFAULT,
                    isLoadDataWithAPI: true
                });
            }
        }
    ]
});

export const beyondLimitTicketAtom = atom({
    key: 'isBeyondLimitTicket',
    default: false
})

export const corpPanelManagementAtom = atom({
    key: 'corpPanelManagementAtom',
    default: {
        isClose: false,
        componentType: ''
    }
})

